.container {
  display: block;
  max-width: 1100px;
  width: 100%;
  margin: 20px auto 0 auto;
}

#imgUpload {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.uploadBtn {
  display: inline-block;
  margin: 20px;
  padding: 14px 20px;
  border: 1px solid grey;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
    background-color: #054c00;
    color: white;
  }
}

#puzzleWrap {
  display: block;
  width: fit-content;
  margin: 0 auto;
}

.select-image {
  display: flex;
  justify-content: center;
}

.done-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    color: #054c00;
  }
}
